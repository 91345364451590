/* DateFilter.css */

  .custom-datetime-picker>.react-datetime-picker__wrapper {
    width: 100px;
    border: 1px solid #8392a53c !important;
    border-radius: 5px;
    margin-left: 15px;
    text-align: center;

  } 
.filtersBox{
    display: flex;
    width:100%;
    height: 50px;
    flex-direction: row;
    background-color: white;
    padding-left: 20px;
    /* justify-content: center; */
    align-items: center;
    border-bottom: 1px solid #8392a53c;
    z-index: 2;
}