@import '../../theme/theme.scss';

#modal-new-post {
    height: auto;
}


.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 23px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.custom-control-input:checked ~ .custom-control-label::before  {
   background-color: $primary-color;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

input {
  &:checked + .slider {
    background-color: $primary-color;
  }

  &:focus + .slider {
    box-shadow: 0 0 1px #0158d4;
  }

  &:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}

.slider.round {
  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
}

.edit-img-btn {
  z-index: 999;
  position: absolute;
  right: 1.5rem;
  top: 5.5rem;
  color: navy;
  background-color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;

  &:hover {
    cursor: pointer;
    background-color: #0158d4;
    color: #fff;
  }
}

.img-preview {
  width: 100%;
  height: 8rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crop-container {
  height: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .reactEasyCrop_Container {
    left: 1rem;
    max-height: 26rem;
  }
}

.controls {
  /* position: absolute; */
}

.save-btns {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0;
  bottom: 0;
}

.mergeFields {
  display: block;
  background-color: #fff;
  border: 1px solid #c0ccda;
  padding-top: 3px;
  padding-bottom: 1px;
  padding-left: 8px;
  padding-right: 8px;
  color: #1b2e4b;
  border-radius: 0.25rem;
  outline: none !important;
  outline-offset: none !important;
}

.igtags-socialnetwork {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.text-current-time{
  color: #001737;
  line-height: .8;
  font-size: 1.2em;
  line-height: .1;
}
.text-current {
  padding: 0.4em;
  font-size: 0.8em;
  margin-left: -1.2em;

}