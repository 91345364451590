
.country-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 25px;
    width: 100%;
  }
  
  .country-logo {
    width: 35px;
    height: 20px;
  }
  
  .selected-logo {
    width: 35px;
    height: 20px;
  }
.selected-option {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
}